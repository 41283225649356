import React from "react";
import HeaderImg from "../res/people.jpg";
import "../styles/Attendees.css";
import Img1 from "../res/attendees/1.jpg";
import Img2 from "../res/attendees/2.jpg";
import Img3 from "../res/attendees/3.jpg";
import Img4 from "../res/attendees/4.jpg";
import Img5 from "../res/attendees/5.jpg";
import { People } from "../components";

function Attendees() {
  const data = [
    {
      filename: Img1,
      name: "Aegerter",
      prename: "Thomas",
      description: "UBS",
    },
    {
      filename: Img2,
      name: "Krueger",
      prename: "Dieter",
      description: "Test",
    },
    {
      filename: Img3,
      name: "Schuhmacher",
      prename: "Lukas",
      description: "Test2",
    },
    {
      filename: Img5,
      name: "Baer",
      prename: "Mike",
      description: "Any Test",
    },
    {
      filename: Img4,
      name: "Probst",
      prename: "Lea",
      description: "Test Anything",
    },
  ];

  return (
    <>
      <div
        className="leading-img"
        style={{ backgroundImage: `url(${HeaderImg})` }}
      />
      <section>
        <h2>Über uns</h2>
        <div className="row-people">
          <People data={data} />
        </div>
      </section>
    </>
  );
}
export default Attendees;

import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "../res/logo/logo.svg";
import "../styles/Header.css";

export default function Header() {
  const stickyHeader = useRef(null);
  var offset = null;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    if (offset === null) {
      offset = stickyHeader.current.offsetTop;
    }
    if (window.pageYOffset > offset) {
      stickyHeader.current.classList.add("sticky");
    } else {
      stickyHeader.current.classList.remove("sticky");
    }
  }

  return (
    <header className="column">
      <div className="logo">
        <Link to="/">
          <img src={Logo} className="logo" alt="Logo" />
          <h1 id="site-title">SPOTLight 2020</h1>
        </Link>
      </div>
      <ul ref={stickyHeader} className="menu">
        <div className="wrapper">
          <li>
            <Link to="/program">Programm</Link>
          </li>
          <li>
            <Link to="/attendees">Teilnehmer</Link>
          </li>
          <li>
            <Link to="/location">Location</Link>
          </li>
          <li>
            <Link to="/hotel">Hotel</Link>
          </li>
        </div>
      </ul>
    </header>
  );
}

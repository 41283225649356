import React from "react";
import Final from "../res/location/Final.mp4";
import "../styles/Home.css";

export default function Home() {
  return (
    <>
      <video
        className="leading-img"
        style={{ height: "unset", minHeight: "450px" }}
        loop
        autoPlay
        muted
        playsinline
      >
        <source src={Final} type="video/mp4" />
        Your browser does not support this video.
      </video>
      <section>
        <h2>Transformation</h2>
        <p>
          Unsere Welt verändert sich: Um sich den verändernden Bedürfnissen von
          Kunden anzupassen, müssen Organisationen und Firmen agiler werden.
          Agilität ist ein Merkmal des Managements einer Organisation
          (Wirtschaftsunternehmen, Non-Profit-Organisation oder Behörde),
          flexibel und darüber hinaus proaktiv, antizipativ und initiativ zu
          agieren, um notwendige Veränderungen einzuführen*.
          <br />
          <br />
          Dies bedingt eine fortlaufende Transformation – nicht nur der
          Strukturen und Organisationen, sondern auch des Mindsets jedes
          einzelnen Mitarbeitenden.
          <br />
          <br />
          Aus diesem Grund werden wir unseren SPOTLight 2020 in Basel unter das
          Motto «Transformation» stellen. Transformation in der Geopolitik,
          Transformation im Werteverständnis unserer Gesellschaft, im Städtebau,
          in der Technik, in der Bildung und in Unternehmen. Tagtäglich treffen
          wir Transformation auf allen möglichen Ebenen an.
          <br />
          <br />
          Unter Transformation in der Betriebswirtschaft versteht man den
          Prozess der Veränderung, vom aktuellen Zustand (IST) hin zu einem
          angestrebten Ziel-Zustand in der nahen Zukunft. Eine Transformation
          repräsentiert einen fundamentalen und dauerhaften Wandel.* Permanente
          Transformationsprozesse sind im heutigen Zeitalter für Unternehmen
          aufgrund der revolutionären Entwicklung (durch Digitalisierung,
          Globalisierung etc.) und dem schnellen Wirtschaftswachstum
          unumgänglich. Unter anderem soll der Transformationsprozess dazu
          dienen, den Veränderungen des digitalen Zeitalters gerecht zu werden
          und sich immer wieder schnell wandelnden Märkten anpassen zu können.
          Während die Wirtschaft als Ganzes schon immer einem gewissen
          Transformationsdrang unterlag, der sich vor allem in
          volkswirtschaftlichen Entwicklungen abzeichnete, lässt sich auf
          betriebswirtschaftlicher Ebene ein konkreter Handlungsrahmen für
          Veränderungsprozesse abbilden. Dieser betrifft neben einer gesamten
          Branche auch das Unternehmen an sich.
          <br />
          <br />
          <span style={{ fontSize: 16 }}>*) Wikipedia</span>
        </p>
      </section>
    </>
  );
}

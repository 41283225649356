import React from "react";
import { Header } from "./components/";
import "./App.css";
import { HashRouter as Router, Route } from "react-router-dom";
import { Home, Program, Attendees, Location } from "./pages";

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Route path="/attendees" component={Attendees} />
        <Route path="/program" component={Program} />
        <Route path="/location" component={Location} />
        <Route path="/" component={Home} exact />
      </Router>
    </div>
  );
}

export default App;

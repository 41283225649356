import React from "react";
import TemplateImg from "../res/dreispitzhalle/1.jpg";
import Img1 from "../res/location/1.jpg";
import Img2 from "../res/location/2.jpg";
import Img3 from "../res/location/3.jpg";
import Img4 from "../res/location/4.jpg";
import Img5 from "../res/location/halle1.jpg";
import Img6 from "../res/location/halle2.jpg";
import Img7 from "../res/location/halle3.jpg";
import Img8 from "../res/location/halle4.jpg";
import Img9 from "../res/location/halle5.jpg";
import "../styles/Location.css";

function Location() {
  return (
    <>
      <div
        className="leading-img"
        style={{ backgroundImage: `url(${TemplateImg})` }}
      />
      <section>
        <h2>Das Dreispitz-Areal – städtebauliche Transformation</h2>
        <p>
          Das Dreispitz-Areal* ist mit rund 50 ha das grösste geschlossene
          Gewerbe- und Dienstleistungsgebiet Basels mit mehreren hundert
          niedergelassenen Betrieben. Der im Baurecht genutzte Grund gehört der
          Christoph Merian Stiftung und liegt je zur Hälfte in den Gemeinden
          Basel und Münchenstein. Eine öffentliche Entwicklungsplanung,
          basierend auf einer Studie der Architekten Herzog & de Meuron, soll
          das industriell geprägte Areal in den nächsten ein bis zwei
          Jahrzehnten zu einem deutlich urbaneren Teil der Basler Agglomeration
          wandeln.
          <br />
          <br />
          Der Dreispitz, dessen Namen von seinem keilförmigen Umriss herrührt,
          ist ein ehemaliges Landwirtschaftsgebiet, das der Stiftungsgründer
          Christoph Merian ab 1840 sukzessive erwarb und seinem Stammsitz
          Brüglingen anschloss. Als blosses Hofgut betrieben es Merian und seine
          Stiftung bis 1901, wobei wiederholt Teile des Areals für öffentliche
          Zwecke abgetreten wurden. Die Umwandlung zu den öffentlichen
          Materiallagerplätzen auf dem Dreispitz (anfänglich in der Grösse von
          rund 8 ha) geschah aufgrund der ungenügenden Güterdepots beim Basler
          Centralbahnhof und der günstigen verkehrstechnischen Lage des
          Dreispitz. Die Stiftung und der Kanton Basel-Stadt einigten sich 1900
          vertraglich auf die Verpachtung des Landes an den Staat und den
          Betrieb durch die staatliche Dreispitzverwaltung, der 1901 begann.
          <br />
          <br />
          Zu den Materiallagerplätzen kam ab 1922 ein Zollfreilager (betrieben
          von der Basler Freilagergesellschaft), wofür erstmals ein Vertrag in
          der Form eines Baurechts abgeschlossen wurde. Diese Rechtsform
          übertrug man 1955 auch auf die Dreispitzverwaltung und löste damit das
          alte Pachtverhältnis ab. Die Dreispitzverwaltung siedelt seither auf
          dem Areal weitere Unternehmen im Unterbaurecht an. An die Stelle der
          reinen Lagerflächen und -gebäude sind mehrere Hundert Produktions- und
          Dienstleistungsbetriebe mit Schwerpunkt Logistik und internationalen
          Verbindungen getreten. (Die nach Handelsstädten wie Frankfurt,
          Rotterdam oder Genua benannten Strassen des Dreispitz weisen darauf
          hin.) Die gewachsene Bedeutung des Areals äussert sich auch in einer
          eigenen Station der Basler S-Bahn. 2005 sind der Kanton Basel-Stadt
          und die Christoph Merian Stiftung übereingekommen, den laufenden
          Baurechtsvertrag vorzeitig aufzulösen. Die Stiftung, die auch schon
          die Aktien der Freilagergesellschaft erworben hat, hat 2008 die
          Dreispitzverwaltung übernommen.
          <br />
          <br />
          Die Betriebsansiedlung im Dreispitz verlief seit 1901 weitgehend ohne
          übergeordnete Zielsetzungen und entzog den Dreispitz zudem der Basler
          Stadtentwicklung. 2002 präsentierten die öffentliche Hand und die
          Stiftung eine Studie des Architekturbüros Herzog & de Meuron über
          mittel- und langfristige Perspektiven des Areals. Diese Vision ist
          2003 in eine Machbarkeitsstudie und 2006 in einen Richtplan überführt
          worden. Ziel ist es, das Areal als neues Entwicklungsgebiet der Region
          Basel aufzuwerten und mit stärker urbanem Charakter für die Stadt und
          die Agglomeration zu öffnen. Geplant sind dabei die Fortführung der
          bisherigen gewerblich-industriellen Nutzung unter Ergänzung durch
          Wohnhäuser und Bildungs- und Kultureinrichtungen. Die Fachhochschule
          Nordwestschweiz (HGK, vom Architekturbüro Morger Partner) hat ihre
          Hochschule für Gestaltung und Kunst, die bislang auf mehrere Standorte
          verteilt war, im Quartier «Kunstfreilager» innerhalb des Dreispitz
          konzentriert. Die Bezeichnung «Kunstfreilager» bezieht sich dabei auf
          die frühere Nutzung des Geländes als Zollfreilager wie auch auf die
          neu angesiedelten Einrichtungen im kreativwirtschaftlichen Bereich
          (Archivgebäude der Architekten Herzog & de Meuron, HGK, Galerien,
          Künstlerateliers, Haus für elektronische Künste Basel samt dem
          Medienfestival «Shift»).
          <br />
          <br />
          <span style={{ fontSize: 16 }}>*) Wikipedia</span>
          <br />
          <br />
          <a href="https://www.dreispitz.ch/de/home.html">
            Webseite der CMS zum Dreispitz-Areal &rarr;
          </a>
        </p>
      </section>
      <section className="column-center">
        <div className="location-img-full-width location-row-1">
          <div style={{ backgroundImage: `url(${Img6})` }} className="half" />
          <div style={{ backgroundImage: `url(${Img3})` }} className="half" />
        </div>
        <div className="location-img-full-width">
          <div
            style={{ backgroundImage: `url(${Img5})` }}
            className="full fixed-bg"
          />
        </div>
        <div className="location-img-full-width">
          <div style={{ backgroundImage: `url(${Img2})` }} className="half" />
          <div style={{ backgroundImage: `url(${Img7})` }} className="half" />
        </div>
        <div className="location-img-full-width">
          <div
            style={{ backgroundImage: `url(${Img4})` }}
            className="full fixed-bg"
          />
        </div>
        <div className="location-img-full-width">
          <div style={{ backgroundImage: `url(${Img8})` }} className="half" />
          <div style={{ backgroundImage: `url(${Img9})` }} className="half" />
        </div>
        <div className="location-img-full-width">
          <div style={{ backgroundImage: `url(${Img1})` }} className="full" />
        </div>
      </section>
    </>
  );
}

export default Location;

import React from "react";
import "../styles/Person.css";

function People(props) {
  const { data } = props;
  return (
    <>
      {data.map((person) => (
        <div className="person">
          <img src={person.filename} alt={person.name + " " + person.prename} />
          <h3>
            {person.name} {person.prename}
          </h3>
          <label>{person.description}</label>
        </div>
      ))}
    </>
  );
}

export default People;

import React from "react";
import "../styles/Timeline.css";

function Timeline(props) {
  const timeline_data = props.data.map((element, i) => (
    <div className={i % 2 === 0 ? "container left" : "container right"}>
      <div className="content">
        <h2>{element.title}</h2>
        <label>{element.person ? element.person : "Alle"}</label>
        <p>{element.text}</p>
      </div>
    </div>
  ));
  return (
    <>
      <div classNameName="border-box">
        <div className="timeline">{timeline_data}</div>
      </div>
    </>
  );
}

export default Timeline;

import React from "react";
import "../styles/Program.css";
import { Timeline } from "../components";
import HeaderImg from "../res/program.jpg";

function Program() {
  const data = [
    {
      title: "bis 10.30",
      text: "Empfang der Gäste (SPOTLight-Studio Basel)",
    },
    {
      title: "10.30 - 10.45",
      text: "Begrüssung und Eröffnung",
      person: "Th. Aegerter",
    },
    {
      title: "10.45 - 11.00",
      text: "Strategie-Input",
      person: "S. Westerfeld",
    },
    {
      title: "11.00 - 12.00",
      text: "Workshop Matterhorn / Workshop Eiger",
    },
    {
      title: "12.00 - 12.30",
      text: "Präsentation Workshops im SPOTLight-Studio",
    },
    {
      title: "12.30 - 13.15",
      text: "Stehlunch",
    },
    {
      title: "13.15 - 14.15",
      text: "Workshop Rothorn / Pilatus",
    },
    {
      title: "14.15 - 14.45",
      text: "Präsentation Workshops im SPOTLight-Studio",
    },
    {
      title: "14.45 - 15.00",
      text: "Pause",
    },
    {
      title: "15.00 - 15.20",
      text: 'Impulsreferat "Transformation"',
      person: "Gast",
    },
    {
      title: "15.20 - 16.30",
      text:
        'Speedshops "Access Branch", "Digital Region", "People Transformation"',
    },
    {
      title: "16.30 - 16.45",
      text: "Closing",
      person: "S. Westerfeld / Th. Aegerter",
    },
    {
      title: "17.00 - 18.00",
      text: 'Inputreferat "Transformation im Dreispitz"',
      person: "Gast",
    },
    {
      title: "18.15 - 19.00",
      text: "Apéro",
    },
    {
      title: "19.00",
      text: "Nachtessen",
    },
    {
      title: "Anschliessend",
      text: "Transfer zum Hotel",
    },
  ];
  return (
    <>
      <div
        className="leading-img"
        style={{ backgroundImage: `url(${HeaderImg})` }}
      />
      <section className="no-padding">
        <Timeline data={data} />
      </section>
    </>
  );
}

export default Program;
